<template>
  <b-row v-if="selection" class="preview-center" no-gutters>
    <b-col>
        <span @click="$emit('on:minus-icon-click')" class="text-danger-light pointer">
          <i class="fa custom-icon-size fa-minus"></i>
        </span>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    selection: {
      type: Boolean,
      default: true,
    }
  }
}
</script>
<style scoped>
.preview-center {
  text-align: center;
}
@media only screen and (max-width: 991px) {
  .preview-center {
    text-align: left;
  }
}
.custom-icon-size {
  font-size: 17px
}
</style>